<template>
    <div>
        <!--        <pay :recharge="recharge" @payClose="payClose"></pay>-->
        <pay :recharge="recharge" @payClose="payClose"></pay>
        <div class="tip_bottom" @click="custom" v-show="isShowTipBottom">
        </div>
        <div class="tip_bottom_text" v-show="isShowTipBottom ">
            <div class="flexDiv" style="display: flex" @click="custom">
                <div class="bottom_text_1">✔︎ 专业标书定制</div>
                <div class="bottom_text_2"> 🟢快速专人对接</div>
                <div class="bottom_text_3">
                    立即咨询
                    <a-icon type="edit" theme="filled"/>
                </div>
            </div>
            <div class="bottom_text_4" @click="closeTipBottom">
                <a-icon type="close-circle" theme="filled" style="font-size: 30px"/>
            </div>

        </div>
        <div class="tip_left" v-show="isShowTipLeft && !isContent">
            <div style="margin-top: 318px;opacity:0.2; margin-left:80px;position: absolute;  cursor: pointer;"
                 @click="closeTipLeft">
                <a-icon type="close-circle" :style="{fontSize: '30px'}"/>
            </div>
            <img style="border-radius: 10px;" src="../../assets/image/img/promote_charge.png"
                 class="responsive-image" @click="getCharge()"/>
        </div>


        <div class="tip_bottom" v-show="isShowTipBottom">
            <div class="bottom"></div>
        </div>
        <div class="tip_right">
            <div @click="custom" class="span_right bid_dz">
                <div class="tip_img"><img src="../../assets/image/img/icon_write.png" alt=""></div>
                <div class="tip_title">标书定制</div>
            </div>
            <div class="span_right bid_gzh">
                <a-popover placement="left">
                    <template slot="content">
                        <div style="min-width: 150px;display: flex;justify-content: space-between">
                            <div class="flex_gzh">
                                <img style="width:120px;" src="../../assets/image/img/icon_gzh_img.jpg" alt="">
                                <div style="width: 100%;text-align: center">微信公众号</div>
                            </div>
                            <div class="flex_gzh">
                                <img style="width:114px;margin-top: 5px" src="../../assets/image/img/icon_ewm.png"
                                     alt="">
                                <div style="width: 100%;text-align: center">微信专属客服</div>
                            </div>
                        </div>
                    </template>
                    <div class="tip_img"><img src="../../assets/image/img/icon_weichat.png" alt=""></div>
                    <div class="tip_title">公众号</div>
                </a-popover>
            </div>
            <div class="span_right bid_chat">
                <div class="czx_atm_next_ccc">
                    <div class="czx_atm_next">
                        <div class="czx_A">
                            <img style="width: 24px;" src="../../assets/image/img/icon_kefu.png" alt="">
                            在线客服
                        </div>
                        <div class="czx_B">
                            <p class="czx_BA">工作时间：9:00-17:30</p>
                            <p class="czx_BB">工作电话：{{ phone }}</p>
                        </div>
                        <div class="czx_C">
                            <a-button @click="chatCustom"
                                      style="background: #1347B4;width: 160px;height: 36px;box-shadow:0px 10px 56px 0px rgba(221,221,221,0.16);border-radius: 6px;color: #F4F5F7 "
                                      icon="customer-service">联系客服
                            </a-button>
                        </div>
                        <div v-show="isQuestion" class="czx_D">
                            <a style="color: #333;font-size: 18px" href="javascript:void (0)" @click="question">
                                <a-icon type="exclamation-circle"/>
                                常见问题</a>
                        </div>
                    </div>
                </div>
                <div class="tip_img"><img src="../../assets/image/img/icon_chat.png" alt=""></div>
                <div class="tip_title">在线客服</div>
            </div>
            <div @click="opinion" style="background: #D4A94A" class="span_right bid_dz">
                <div class="tip_img"><img style="height: 26px" src="../../assets/image/img/icon_edit_fk.png" alt="">
                </div>
                <div class="tip_title">意见反馈</div>
            </div>
            <div class="span_right collection" @click="bookmark">
                <div class="lachesism_atm_next_ccc">
                    <div class="lachesism_atm_next">
                        <div class="lachesism_B">
                            <p class="lachesism_BA">收藏本站</p>
                            <div style="display: flex">
                                <p class="lachesism_BB">【Crtl + D】</p>
                                <p class="lachesism_BC">快速收藏本站</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tip_img"><img src="../../assets/image/img/bookmark.png" alt=""></div>
                <div class="tip_title">收藏本站</div>
            </div>
        </div>
        <a-modal
                :footer="null"
                width="600px"
                :dialog-style="{ top: '200px',borderRadius:'30px',padding:0}"
                :visible="opinionShow"
                :maskClosable="false"
                @cancel="() => setModal1Visible(false)"
        >
            <div class="tip_opinion">
                <div class="opinion_title">感谢你留下宝贵的建议</div>
                <div class="opinion_content">
                    <div class="opinion_content_body">
                        <div class="opinion_left">反馈类型：</div>
                        <div class="opinion_right" style="display: flex;height: 40px;align-items: center">
                            <a-button @click="form.type=0" :class="form.type===0 ? 'spans_active':'spans'">找不到文档
                            </a-button>
                            <a-button @click="form.type=1" :class="form.type===1 ? 'spans_active':'spans'">使用问题
                            </a-button>
                            <a-button @click="form.type=2" :class="form.type===2 ? 'spans_active':'spans'">其他
                            </a-button>
                        </div>
                    </div>
                    <div class="opinion_content_body">
                        <div class="opinion_left"><b style="color: #CC404D">*</b> 反馈内容：</div>
                        <div class="opinion_right">
                            <a-textarea
                                    v-model="form.feedback"
                                    placeholder="请填写问题描述以便我们提供更好的帮助"
                                    :auto-size="{ minRows: 8, maxRows: 15 }">
                            </a-textarea>
                        </div>
                    </div>
                    <div v-if="isLogin ? false:true" class="opinion_content_body" style="margin-top: 15px">
                        <div class="opinion_left"><b style="color: #CC404D">*</b> 联系方式：</div>
                        <div class="opinion_right">
                            <a-input v-model="form.phone" style="height: 44px" placeholder="请输入手机号码"></a-input>
                        </div>
                    </div>

                </div>
                <div class="opinion_submit">
                    <a-button @click="submitOpinion" type="primary">提交</a-button>
                </div>
            </div>
        </a-modal>
        <login :login-show="loginShows" @submitLoginClose="submitLoginClose" @loginSuccess="loginSuccess"></login>
    </div>
</template>

<script>
import {navigateUrl} from "@/utils/resToData";
import {decrypt} from "@/utils/AES";
import {addfeedback} from "@/api/page/moduleTopic";
import {phoneNumCheck} from "@/utils/checked";
import {addBehavior} from "@/data/accessKey";
import {getAgreement} from "@/api/page/public";
import login from "@/views/page_login/login.vue";
import pay from "@/views/page_pay/pay.vue";
import {parseTime} from "@/utils/parse";

export default {
    name: "view_right_tip",
    components: {pay, login},
    data() {
        return {
            isShowTipLeft: true,
            loginShows: false,
            recharge: false,
            phone: process.env.VUE_APP_PHONE,
            opinionShow: false,
            formLayout: 'horizontal',
            form: {feedback: '', phone: '', type: 0},
            isLogin: false,
            isQuestion: false,
            questionValue: {},
            beginTime: Date.parse(new Date()),
            isShowTipBottom: true,
            isContent: false
        }
    },

    methods: {
        closeTipBottom() {
            const currentTime = new Date();
            const futureTime = new Date(currentTime.getTime() + 60 * 10 * 1000);
            localStorage.setItem("isShowTipBottom", JSON.stringify({
                value: "false",
                expiration: futureTime.toLocaleString()
            }));
            this.isShowTipBottom = false
        },
        payClose() {
            this.recharge = false
        },
        loginSuccess() {
            this.$bus.$emit('loginSuccessInfo', true)
            this.loginStatus = true
        },
        submitLoginClose() {
            this.loginShows = false
        },
        question() {
            this.$router.push({path: '/webState', query: {change: this.questionValue.id}})
        },
        getCharge() {
            if (localStorage.getItem('accessKey') && localStorage.getItem('userInfo')) {
                this.recharge = true
                //打开充值
            } else {
                this.loginShows = true
                //跳转登录
            }
        },
        closeTipLeft() {
            const currentTime = new Date();
            const futureTime = new Date(currentTime.getTime() + 60 * 10 * 1000);
            localStorage.setItem("isShowTipLeft", JSON.stringify({
                value: "false",
                expiration: futureTime.toLocaleString()
            }));
            this.isShowTipLeft = false
        },
        bookmark() {
            var url = 'https://www.biaoxiaozhu.cn/#/';
            var title = '标小助投标文件网';
            if (window.sidebar && window.sidebar.addPanel) { // Firefox
                window.sidebar.addPanel(title, url, '');
            } else if (window.external && ('AddFavorite' in window.external)) { // IE
                window.external.AddFavorite(url, title);
            } else if (window.opera && window.print) { // Opera
                var elem = document.createElement('a');
                elem.setAttribute('href', url);
                elem.setAttribute('title', title);
                elem.setAttribute('rel', 'sidebar');
                elem.click();
            } else { // Other browsers
                // alert('请按下Ctrl+D手动添加到收藏夹');
            }
        },
        custom() {
            if (this.$route.path === '/custom') {
                this.$router.push({path: '/custom'}).catch(err => {
                })
            } else {
                navigateUrl('/custom')
            }
        },
        chatCustom() {
            // this.customeShow=false
            //
            // let hm = document.createElement("script");
            // hm.src = "https://hm.baidu.com/hm.js?efce94ff47728f12a211f329eec7c3ea";
            //
            // let s = document.getElementsByTagName("script")[0];
            // s.parentNode.insertBefore(hm, s);

            // //方法三、在小窗口弹出沟通窗口
            var url = "https://affim.baidu.com/unique_49213756/chat?siteId=19557841&userId=49213756&siteToken=efce94ff47728f12a211f329eec7c3ea";
            var name = "标小助"; //网页名称，可为空;
            var iWidth = 780; //弹出窗口的宽度;
            var iHeight = 600; //弹出窗口的高度;
            var iTop = (window.screen.height - 30 - iHeight) / 2; //获得窗口的垂直位置;
            var iLeft = (window.screen.width - 10 - iWidth) / 2; //获得窗口的水平位置;
            window.open(url, name, 'height=' + iHeight + ',,innerHeight=' + iHeight + ',width=' + iWidth + ',innerWidth=' + iWidth + ',top=' + iTop + ',left=' + iLeft + ',toolbar=no,menubar=no,scrollbars=auto,resizeable=no,location=no,status=no');
        },

        opinion() {
            let user = localStorage.getItem('userInfo')
            if (user) {
                this.form.phone = JSON.parse(decrypt(user)).phone
            } else {
            }
            this.opinionShow = true
        },

        setModal1Visible() {
            this.form.content = this.form.phone = ''
            this.opinionShow = false
        },
        submitOpinion() {
            let user = localStorage.getItem('userInfo')
            if (user) {
                this.form.phone = JSON.parse(decrypt(user)).phone
                if (this.form.feedback === '') {
                    this.$message.error('反馈内容为必填')
                } else {

                    addfeedback(this.form).then(res => {
                        this.opinionShow = false
                        this.$message.success('反馈成功，等待管理员反馈')
                        this.form = {feedback: '', phone: '', type: 0}
                    }).catch(err => {
                    })
                }
            } else {
                if (this.form.feedback === '') {
                    this.$message.error('反馈内容为必填')
                } else if (this.form.phone === '') {
                    this.$message.error('手机号码为必填')
                } else {
                    if (phoneNumCheck(this.form.phone)) {
                        let form = {feedback: this.form.content, phone: this.form.phone, type: this.form.type}
                        addfeedback(this.form).then(res => {
                            this.opinionShow = false
                            addBehavior('意见反馈', '意见反馈', location.href, this.beginTime, Date.parse(new Date()))
                            this.$message.success('反馈成功，等待管理员反馈')
                            this.form = {feedback: '', phone: '', type: 0}
                        }).catch(err => {
                        })
                        // this.$message.success('反馈成功')
                        this.opinionShow = false
                        this.form = {content: '', phone: '', type: 0}
                    } else {
                        this.$message.error('手机号码格式不正确')
                    }
                }
            }
        },


    },

    mounted() {
        var currentURL = window.location.href;
        if (currentURL.includes('content?doc') || currentURL.includes('custom')) {
            this.isShowTipBottom = false;
        }
        if (currentURL.includes('content?doc') && localStorage.getItem('userInfo')) {
            this.isContent = true
        } else {
            this.isContent = false
        }
        let user = localStorage.getItem('userInfo')
        if (user) {
            this.form.phone = JSON.parse(decrypt(user)).phone
            this.isLogin = true
        } else {
            this.isLogin = false
        }
        if (localStorage.getItem("isShowTipLeft")) {
            const data = JSON.parse(localStorage.getItem("isShowTipLeft"));
            if (data && data.expiration.toLocaleString() < new Date().toLocaleString()) {
                localStorage.removeItem("isShowTipLeft");
                this.isShowTipLeft = true;
            } else {
                this.isShowTipLeft = false;
            }
        }
        if (localStorage.getItem("isShowTipBottom")) {
            const data = JSON.parse(localStorage.getItem("isShowTipBottom"));
            if (data && data.expiration.toLocaleString() < new Date().toLocaleString()) {
                localStorage.removeItem("isShowTipBottom");
                this.isShowTipBottom = true;
            } else {
                this.isShowTipBottom = false;

            }
        }
        getAgreement().then(res => {
            if (res) {
                res.forEach(value => {
                    if (value.name === '常见问题') {
                        this.isQuestion = true
                        this.questionValue = value
                    }
                })
            }
        })

    },


    watch: {
        opinionShow(newValue, oldValue) {
            if (newValue) {
                let user = localStorage.getItem('userInfo')
                if (user) {
                    this.form.phone = JSON.parse(decrypt(user)).phone
                    this.isLogin = true
                } else {
                    this.isLogin = false
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
.tip_bottom {
  position: fixed;
  top: 93%;
  width: 100%;
  min-height: 7%;
  z-index: 998;
  background-color: rgba(12, 2, 2, 0.4);
}

.tip_bottom_text {
  cursor: pointer;
  position: fixed;
  top: 92%;
  width: 100%;
  min-height: 8%;
  z-index: 999;
}

.flexDiv {
  color: white;
  font-size: 30px;
  font-family: "Heiti TC";

}

.bottom_text_1 {
  margin-top: 18px;
}

.bottom_text_1 {
  margin-left: 35%;
}

.bottom_text_2 {
  margin-top: 30px;
  font-size: 18px;
  margin-left: 1%;
}

.bottom_text_3 {
  margin-top: 18px;
  font-family: fangsong;
  margin-left: 30%;
  background-color: rgba(248, 68, 3, 0.95);
  color: white;
  padding: 6px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  animation: pulse 1s infinite; /* Add pulse animation */
}

.bottom_text_4 {

  position: absolute;
  top: -30px;
  color: #000000;
  left: 98%;
}

@keyframes pulse {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0);
  }
}


.tip_left {
  z-index: 999;
  position: fixed;
  min-height: 500px;
  width: 75px;
  left: 20px;
  top: 500px;
  text-align: center;
}


.tip_left :hover {
  animation: scaleAnimation 2s infinite;

}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.closeRightTip {
  margin-left: 10px;
  font-size: 40px;

}

.closeRightTip :hover {
  opacity: 0.8;
  cursor: pointer;
}

.tip_img :hover {
  scale: 1.1;
  cursor: pointer;

}

.tip_right {
  min-height: 300px;
  width: 75px;
  z-index: 999;
  position: fixed;
  right: 10px;
  top: 410px;

  .span_right {
    width: 65px;
    height: 65px;
    margin-top: 10px;
    border-radius: 10px;


    .tip_img {
      width: 100%;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .tip_title {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 14px;
    }
  }

  .span_right:hover {
    scale: 1.2;
  }

  .bid_dz {
    background: #588cc7;
  }

  .bid_gzh {
    background: #14ba7d;
  }

  .bid_chat {
    background: #1fa6ca;
  }

  .bid_chat:hover .czx_atm_next_ccc {
    display: block;
  }

  .collection {
    background: #da541b;
  }

  .collection:hover .lachesism_atm_next_ccc {
    display: block;
  }
}

.tip_opinion {
  width: 90%;
  min-height: 410px;

  .opinion_title {
    text-align: center;
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    color: #333333;
  }

  .opinion_content {
    width: 100%;
    min-height: 300px;
    margin-top: 20px;

    .opinion_content_body {
      width: 100%;
      min-height: 50px;
      display: flex;
      justify-content: space-between;

      .opinion_left {
        width: 120px;
        height: 40px;
        justify-content: center;
        color: #333333;
        font-size: 16px;
        display: flex;
        align-items: center;
        background: #fff;
      }

      .opinion_right {
        width: 100%;
        min-height: 40px;
        color: #333333;
        font-size: 16px;
        display: flex;
        background: #fff;

        .spans {
          float: right;
          margin-right: 20px
        }

        .spans_active {
          float: right;
          margin-right: 20px;
          border: 1px solid #2080e5;
          color: #2080e5;
        }
      }
    }
  }

  .opinion_submit {
    width: 100%;
    margin-top: 15px;
    text-align: center;

    button {
      width: 224px;
      height: 44px;
      background: #2080E5;
      border-radius: 4px;
      font-size: 16px;
    }
  }
}

.czx_atm_next_ccc {
  display: none;
  width: 280px;
  position: absolute;
  right: 60px;
  background: none;
  height: 260px;

  .czx_atm_next {
    width: 260px;
    min-height: 200px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 24px 26px;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: all 0.3s ease-in-out;

    .czx_A {
      width: 100%;
      font-size: 18px;
      font-weight: 800;
      color: #333333;
    }

    .czx_B {
      margin-top: 16px;

      .czx_BA {
        padding: 0;
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        line-height: 26px;
      }

      .czx_BB {
        padding: 0;
        margin: 5px 0 0 0;
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        line-height: 26px;
      }
    }

    .czx_C {
      width: 100%;
      margin-top: 14px;
    }

    .czx_D {
      width: 100%;
      margin-top: 10px;
      font-weight: 800;
    }
  }
}

.lachesism_atm_next_ccc {
  display: none;
  width: 280px;
  position: absolute;
  right: 60px;
  background: none;
  height: 260px;

  .lachesism_atm_next {
    width: 220px;
    min-height: 80px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 14px 10px;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: all 0.3s ease-in-out;

    .lachesism_B {
      margin-top: 1px;

      .lachesism_BA {
        padding: 0;
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        line-height: 26px;
      }

      .lachesism_BB {
        padding: 0;
        margin: 5px 0 0 0;
        font-size: 16px;
        font-weight: 500;
        color: #ff0000;
        line-height: 26px;
      }

      .lachesism_BC {
        padding: 0;
        margin: 5px 0 0 0;
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        line-height: 26px;
      }
    }

    .czx_C {
      width: 100%;
      margin-top: 14px;
    }

    .czx_D {
      width: 100%;
      margin-top: 10px;
      font-weight: 800;
    }
  }
}

.responsive-image {
  cursor: pointer;
}

</style>
<style scoped>
>>> .ant-modal-content {
    border-radius: 19px;
}
</style>